<template>
    <div :class="getType" class="notif-style">
        <div class="alert-content">
            <img :title="getText" alt="" src="@/assets/img/alert.svg">
            <span>{{ getText }}</span>
        </div>
        <div class="alert-close">
            <img :title="$t('paste')" alt="" src="@/assets/img/closeee.svg" @click="$emit('close-toast')">
        </div>
    </div>
</template>

<script>
export default {
    name: "Notif",
    props: {
        type: {
            default: null,
        },
        text: {
            default: null,
            type: [String, Object]
        }
    },
    computed: {
        getType() {
            if (this.type == 'error')
                return 'alert-error';
            else if (this.type == 'warning')
                return 'alert-warning';
            else if (this.type == 'green')
                return 'alert-success';
            return 'alert-success';
        },
        getText() {
            return this.text ? this.text : this.$t('success');

        }
    },
};
</script>

<style>
.Vue-Toastification__toast {
    box-shadow: none !important;
    background: none !important;
    /*padding: 0px 0px 0px 24px !important;*/
    min-width: 0px !important;
    padding: 0px !important;
    margin-bottom: 0px !important;
    overflow: visible !important;
}

.Vue-Toastification__toast-component-body {
    flex: none !important;
}

.Vue-Toastification__toast .Vue-Toastification__progress-bar {
    height: 0px !important;
}

.Vue-Toastification__container {
    padding: 0px !important;
    bottom: 15px !important;
    right: 15px !important;
}

.alert {
    position: relative !important;
    right: 0px !important;
    bottom: 0px !important;
    cursor: pointer !important;
}

.notif-style {
    right: -1px !important;
}
</style>