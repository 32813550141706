import Vue from "vue";
import axios from "axios";
import i18nService from "@/_services/i18n.service";
import Notif from "@/components/UI/Notif";
import router from "@/router";


const client = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL || 'http://localhost',
    headers: {
        'Content-Type': 'application/json',
        'X-Localization': i18nService.getCurrentLocale(),
        'service': 2,

    },
    // onUploadProgress: function (progressEvent) {
    //     const uploadPercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    //
    // }
    // }.bind(this),
});

client.interceptors.response.use(res => res, error => {
    console.log(error);
    console.log(error.response);
    if (error.response.status === 500) {
        Vue.prototype.$notyf.error({
            message: Vue.prototype.$t('server not responding'),
        });
    } else if (error.response.status === 403 && error.response.data.message == 'no permission') {
        router.back();
    }
    throw error;
});

export const setTokenToClient = token => {
    client.defaults.headers.common.Authorization = 'Bearer ' + token;
};
export const removeTokenFromClient = () => {
    delete client.defaults.headers.common.Authorization;
};

export function handleResponse(res) {
    return res.data;
}

export function handleError(error) {
    if (error.response.status === 401) {
        Vue.prototype.$notyf.error({
            message: Vue.prototype.$t('authorisation required')
        });
    }
    if (error.response.status === 403)
        if (error.response.data.message == 'no role access')
            router.push({name: 'courses'});
    if (error.response.data.message === 'one comment in minute') {
        Vue.prototype.$notyf.error({
            message: Vue.prototype.$t('one comment in minute')
        });
    }
    throw error;
}

export default client;